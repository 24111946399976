html,
body,
#root {
    min-height: 100vh;
}

body {
    padding: 0;
    margin: 0;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
